// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
    width: 100%;
    max-width:540px;
    height: 400px;
    border: 1px solid #ccc;
    overflow-y: auto;
  }
  
  .message {
    padding: 8px;
    margin: 8px;
    border-radius: 8px;
  }
  
  .sent {
    background-color: #0070DA;
    color: #ffffff;
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    width:75%;
  }
  
  .received {
    background-color: #3D4648;
    color: #ffffff;
    margin-right: auto;
    align-self: flex-start;
    display: flex;
    justify-content: flex-start;
    width:75%;
  }



  

`, "",{"version":3,"sources":["webpack://./src/component/ManageBotPage/histroy.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,oBAAoB;IACpB,aAAa;IACb,yBAAyB;IACzB,iBAAiB;IACjB,SAAS;EACX;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,2BAA2B;IAC3B,SAAS;EACX","sourcesContent":[".chat-container {\n    width: 100%;\n    max-width:540px;\n    height: 400px;\n    border: 1px solid #ccc;\n    overflow-y: auto;\n  }\n  \n  .message {\n    padding: 8px;\n    margin: 8px;\n    border-radius: 8px;\n  }\n  \n  .sent {\n    background-color: #0070DA;\n    color: #ffffff;\n    align-self: flex-end;\n    display: flex;\n    justify-content: flex-end;\n    margin-left: auto;\n    width:75%;\n  }\n  \n  .received {\n    background-color: #3D4648;\n    color: #ffffff;\n    margin-right: auto;\n    align-self: flex-start;\n    display: flex;\n    justify-content: flex-start;\n    width:75%;\n  }\n\n\n\n  \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
