import React from 'react'
import VerticalNavDash from '../../component/DashboardPage/VerticalNavDash'
import MyChatbotsDash from '../../component/DashboardPage/MyChatbotsDash'

const MyChatbotsDashPage = () => {
  return (
    <div>
    <VerticalNavDash/>
   <MyChatbotsDash/>
  </div>
  )
}

export default MyChatbotsDashPage