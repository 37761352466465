import React from 'react'
import VerticalNavDash from '../../component/DashboardPage/VerticalNavDash'
import TokensLeftClient from '../../component/DashboardPage/TokensLeftClient'

const TokensLeftPage = () => {
  return (
    <div>
      <VerticalNavDash/>
     <TokensLeftClient/>
    </div>
  )
}

export default TokensLeftPage