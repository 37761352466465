import React from 'react'
import VerticalNavDash from '../../component/DashboardPage/VerticalNavDash'
import FailedConversationClient from '../../component/DashboardPage/FailedConversationClient'

const FailedCinversationClientPage = () => {
  return (
    <div>
    <VerticalNavDash/>
   <FailedConversationClient/>
  </div>
  )
}

export default FailedCinversationClientPage