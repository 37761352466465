import React from 'react'
import VerticalNavDash from '../../component/DashboardPage/VerticalNavDash'
import CreateChatbotDash from '../../component/DashboardPage/CreateChatbotDash'

const CreateChatbotDashPage = () => {
  return (
    <div>
    <VerticalNavDash/>
   <CreateChatbotDash/>
  </div>
  )
}

export default CreateChatbotDashPage