import React from 'react'
import VerticalNavDash from '../../component/DashboardPage/VerticalNavDash'
import TokenLeftClientBot from '../../component/DashboardPage/TokenLeftClientBot'

const TokensLeftBotPage = () => {
  return (
    <div>
    <VerticalNavDash/>
   <TokenLeftClientBot/>
  </div>
  )
}

export default TokensLeftBotPage