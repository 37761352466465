import React from 'react'
import VerticalNavDash from '../component/DashboardPage/VerticalNavDash'
import DashboardForClient from '../component/DashboardPage/DashboardForClient'

const Dashboard = () => {
  return (
    <div>
      <VerticalNavDash/>
      <DashboardForClient/>
    </div>
  )
}

export default Dashboard