// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sectionOneGradient {
    background: linear-gradient(107.97deg, rgb(253, 121, 48) 0%, rgb(191, 0, 183) 100%);
  }

  .hovercolor{
    background-color: whitesmoke;
  }
  /* .hovercolor:hover{
    background-color: white;
    box-shadow: 5px 5px 20px white;
  } */
  .hovertran:hover{
    transform: translateY(15px);
    transition: ease-in-out 0.3s;
    
  }
  /* .hovertran:hover div.hovercolor{
    background-color: white;
    box-shadow: 5px 5px 20px white;

  } */

  /* @media (max-width: 576px) { */
   .brt{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
   }
   .bri{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow:0px -2px 5px grey;
   }
   .hovertran:hover div.hovercolor{
    background-color: white;
    box-shadow: 5px 5px 20px white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  /* } */
  /* @media (min-width: 577px) {
   .brt{
     border-bottom-right-radius :15px;
     border-top-right-radius:15px;
   }
   .bri{
    border-bottom-left-radius:15px;
     border-top-left-radius:15px;
     box-shadow:-2px 0px 5px grey;
   }
   .hovertran:hover div.hovercolor{
    background-color: white;
    box-shadow: 5px 5px 20px white;
    border-bottom-right-radius :15px;
    border-top-right-radius:15px;
  }

  } */
  `, "",{"version":3,"sources":["webpack://./src/component/BlogPage/gradientCss.css"],"names":[],"mappings":"AAAA;IACI,mFAAmF;EACrF;;EAEA;IACE,4BAA4B;EAC9B;EACA;;;KAGG;EACH;IACE,2BAA2B;IAC3B,4BAA4B;;EAE9B;EACA;;;;KAIG;;EAEH,gCAAgC;GAC/B;IACC,+BAA+B;IAC/B,gCAAgC;GACjC;GACA;IACC,4BAA4B;IAC5B,6BAA6B;IAC7B,4BAA4B;GAC7B;GACA;IACC,uBAAuB;IACvB,8BAA8B;IAC9B,+BAA+B;IAC/B,gCAAgC;EAClC;EACA,MAAM;EACN;;;;;;;;;;;;;;;;;KAiBG","sourcesContent":[".sectionOneGradient {\n    background: linear-gradient(107.97deg, rgb(253, 121, 48) 0%, rgb(191, 0, 183) 100%);\n  }\n\n  .hovercolor{\n    background-color: whitesmoke;\n  }\n  /* .hovercolor:hover{\n    background-color: white;\n    box-shadow: 5px 5px 20px white;\n  } */\n  .hovertran:hover{\n    transform: translateY(15px);\n    transition: ease-in-out 0.3s;\n    \n  }\n  /* .hovertran:hover div.hovercolor{\n    background-color: white;\n    box-shadow: 5px 5px 20px white;\n\n  } */\n\n  /* @media (max-width: 576px) { */\n   .brt{\n    border-bottom-left-radius: 15px;\n    border-bottom-right-radius: 15px;\n   }\n   .bri{\n    border-top-left-radius: 15px;\n    border-top-right-radius: 15px;\n    box-shadow:0px -2px 5px grey;\n   }\n   .hovertran:hover div.hovercolor{\n    background-color: white;\n    box-shadow: 5px 5px 20px white;\n    border-bottom-left-radius: 15px;\n    border-bottom-right-radius: 15px;\n  }\n  /* } */\n  /* @media (min-width: 577px) {\n   .brt{\n     border-bottom-right-radius :15px;\n     border-top-right-radius:15px;\n   }\n   .bri{\n    border-bottom-left-radius:15px;\n     border-top-left-radius:15px;\n     box-shadow:-2px 0px 5px grey;\n   }\n   .hovertran:hover div.hovercolor{\n    background-color: white;\n    box-shadow: 5px 5px 20px white;\n    border-bottom-right-radius :15px;\n    border-top-right-radius:15px;\n  }\n\n  } */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
