// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient-text {
    background: linear-gradient(to bottom right, #FC7731, #F1614C, #E0406E, #CC1A9A, #C105B1);
    -webkit-background-clip: text;
    background-clip: text;;
    color: transparent;
  }

/* Define the gradient */
.gradient-icon {
    display: inline-block;
    background: linear-gradient(to bottom right, #FC7731, #F1614C, #E0406E, #CC1A9A, #C105B1);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .gradient-icon svg {
    fill: url(#gradient); /* Apply gradient to SVG fill */
    width: 100%; /* Ensure the icon takes the full size of the container */
    height: 100%;
  }
  
  .gradient-defs {
    display: none; /* Hide the gradient definition */
  }`, "",{"version":3,"sources":["webpack://./src/component/FeaturePage/featuregrad.css"],"names":[],"mappings":"AAAA;IACI,yFAAyF;IACzF,6BAA6B;IAC7B,qBAAqB;IACrB,kBAAkB;EACpB;;AAEF,wBAAwB;AACxB;IACI,qBAAqB;IACrB,yFAAyF;IACzF,6BAA6B;IAC7B,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,oBAAoB,EAAE,+BAA+B;IACrD,WAAW,EAAE,yDAAyD;IACtE,YAAY;EACd;;EAEA;IACE,aAAa,EAAE,iCAAiC;EAClD","sourcesContent":[".gradient-text {\n    background: linear-gradient(to bottom right, #FC7731, #F1614C, #E0406E, #CC1A9A, #C105B1);\n    -webkit-background-clip: text;\n    background-clip: text;;\n    color: transparent;\n  }\n\n/* Define the gradient */\n.gradient-icon {\n    display: inline-block;\n    background: linear-gradient(to bottom right, #FC7731, #F1614C, #E0406E, #CC1A9A, #C105B1);\n    -webkit-background-clip: text;\n    background-clip: text;\n    color: transparent;\n  }\n  \n  .gradient-icon svg {\n    fill: url(#gradient); /* Apply gradient to SVG fill */\n    width: 100%; /* Ensure the icon takes the full size of the container */\n    height: 100%;\n  }\n  \n  .gradient-defs {\n    display: none; /* Hide the gradient definition */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
