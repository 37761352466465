// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.underline-grow {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: inherit;
  }
  
  .underline-grow::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #FFFFFF; /* Replace with your desired underline color */
    transition: width 0.5s ease;
  }
  
  .underline-grow:hover::after {
    width: 100%;
  }

  .custom-accordion .accordion-button{
    background-color: #212529; /* Replace with the desired color */
    color:#FFFFFF
  }
`, "",{"version":3,"sources":["webpack://./src/component/underline.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,SAAS;IACT,QAAQ;IACR,WAAW;IACX,yBAAyB,EAAE,8CAA8C;IACzE,2BAA2B;EAC7B;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,yBAAyB,EAAE,mCAAmC;IAC9D;EACF","sourcesContent":[".underline-grow {\n    display: inline-block;\n    position: relative;\n    text-decoration: none;\n    color: inherit;\n  }\n  \n  .underline-grow::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    bottom: 0;\n    width: 0;\n    height: 2px;\n    background-color: #FFFFFF; /* Replace with your desired underline color */\n    transition: width 0.5s ease;\n  }\n  \n  .underline-grow:hover::after {\n    width: 100%;\n  }\n\n  .custom-accordion .accordion-button{\n    background-color: #212529; /* Replace with the desired color */\n    color:#FFFFFF\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
