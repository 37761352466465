import React,{useState,useEffect, useRef } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import { LineChart, Line, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { ToastContainer, toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs'
import Cookies from 'js-cookie';
import { ThreeDots, TailSpin } from 'react-loader-spinner';

function CustomTooltip({ payload, label, active }) {
    if (active) {
      return (
        <div className="custom-tooltip " style={{ backgroundColor: '#FFFFFF', color: 'black' }} >
          <p className="label">{`${label}`}</p> <p> {`${payload[0]?.value}`}</p>
        </div>
      );
    }
    return null;
  }
  function CustomTooltip2({ payload, label, active }) {
    if (active) {
      return (
        <div className="custom-tooltip " style={{ backgroundColor: '#FFFFFF', color: 'black' }} >
          <p className="label">{`${label}`}</p> <p> {`${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  }  

const TokenLeftClientBot = () => {

    const BACKEND = process.env.REACT_APP_BACKEND
    const decoded = Cookies.get('accessToken');
    const { id } = useParams();
    const refBot = useRef()
    const itemsPerPage = 10;

    const [data, setData] = useState([])
    const [botname, setBot] = useState('')
    const [dataArr, setDataArr] = useState({})
    const [tokenDataGraph, setTokenDataGraph] = useState('')
    const [conDataGraph, setConDataGraph] = useState('')
    const [selectedTime, setSelectedTime] = useState('');
    const [filteredDataBot, setFilteredDataBot] = useState([])
    const [currentPageBot, setCurrentPageBot] = useState(1);
    const [searchTermBot, setSearchTermBot] = useState([]);
  const [isOpenBot, setIsOpenBot] = useState(false);
  const [comData, setComData] = useState([])
  const [sortOrderBot, setSortOrderBot] = useState('asc');
  const [apiload,setApiload] = useState(false)

    // const sortDataBot = (order, sortBy) => {
    //     const sorted = [...comData].sort((a, b) => {
    //       let valueA, valueB;
    
    //       if (sortBy === 'uniCon') {
    //         valueA = a.ConNo;
    //         valueB = b.ConNo;
    //       } else if (sortBy === 'tokenUsage') {
    //         valueA = a.usage;
    //         valueB = b.usage;
    //       }
    
    //       if (order === 'asc') {
    //         return valueA - valueB;
    //       } else if (order === 'desc') {
    //         return valueB - valueA;
    //       } else {
    //         return 0;
    //       }
    //     });
    
    //     if (order === '') {
    //       setFilteredDataBot([...filteredDataBot]);
    //     } else {
    //       setFilteredDataBot(sorted);
    //     }
    //     setSortOrderBot(order);
    //   };
    
    
      const [minHeight, setMinHeight] = useState(500);

    useEffect(() => {
        if(id !==''){
          console.log(id,"-------87")
        axios.get(`${BACKEND}api/updatebot/${id}`, {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*'
        })
        .then(res => 
          { setDataArr(res.data);console.log("---92----",res.data);return res.data })
          .then(data =>
             {
          console.log(data,"--------94",data.botname)
            console.log(data?.tokenData,"-----------96",data,data?.length)
              setBot(data.botname)
              setTokenDataGraph(data.tokenData)
              setConDataGraph(data.UniqueConData)
              console.log("above create g")
        handleCreateGraph()
        })
        
      }
      }, [id])
  

      const handleCreateGraph = () => {
        console.log('in create graph ',id)
        axios.post(`${BACKEND}api/chartToken/${id}`, { selectedTime }, {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }).then(res => { if (res.data === 'nodata') { console.log(res.data); toast.error("Data Doesn't exist for this date ") }
         else if (res.data[0] == 'error') { console.log(res.data[1]); toast.error("Error Fetching data"); } 
         else {  setTokenDataGraph(res.data);console.log('set token graph',res.data) } }).catch(err => console.log(err))
      }


    
      function abbrNum(number, decPlaces) {

        decPlaces = Math.pow(10, decPlaces);
        var abbrev = ["k", "m", "b", "t"];
        for (var i = abbrev.length - 1; i >= 0; i--) {
          var size = Math.pow(10, (i + 1) * 3);
          if (size <= number) {
            number = Math.round(number * decPlaces / size) / decPlaces;
            if ((number == 1000) && (i < abbrev.length - 1)) {
              number = 1;
              i++;
            }
            number += abbrev[i];
            break;
          }
        } 
        return number;
      }

      const handleSearchBot = (e) => {
        const input = e.target.value;
        setSearchTermBot(input);
    
        const filtered = comData.filter((item) => {
          const regex = new RegExp(input, 'i');
          for (const key in item) {
            if (item.hasOwnProperty(key) && regex.test(item[key])) {
              return true;
            }
          }
          return false;
        });
        setFilteredDataBot(filtered);
        console.log("filter 141 ",filtered)
    
      };

      const toggleDropdownBot = () => {
        setIsOpenBot(!isOpenBot);
      };
    
      useEffect(() => {
        setCurrentPageBot(1)
      }, [filteredDataBot]);
    
      const startIndexBot = (currentPageBot - 1) * itemsPerPage;
      const endIndexBot = startIndexBot + itemsPerPage;
    
      const itemsToDisplayDataBot = filteredDataBot.slice(startIndexBot, endIndexBot);
    
      const handlePageChangeBot = (page) => {
        if (page >= 1 && page <= Math.ceil(filteredDataBot.length / itemsPerPage)) {
          setCurrentPageBot(page);
        }
      };

      useEffect(() => {
        console.log("176---- ",id)
        setApiload(true)
        if(id !== ''){
          console.log("179---- ")
        axios.post(`${BACKEND}api/tokensleftdashbot/${id}`, { decoded}, {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*'
        })
          .then(res => {
            if(res.data[1] == 'ok'){
            setData(res.data[0]); console.log(res.data[0],"------185")
            setApiload(false)
          }
          else if(res.data == 'You Are Not Authorized'){setApiload(false);toast.error('You Are Not Authorized');console.log('187---')}
          else{console.log(res.data,"---188");toast.error("Some Error Occured!!!");setApiload(false)}})
          .catch(err => {console.log(err,"----189");setApiload(false)})
          console.log('190')
        }
      }, [id])

      useEffect(() => {
        if (data.length !== 0) {
    
          const combinedData = [];
          // Combine data from tokenData
          for (let i = 0; i < data.tokenData.length; i++) {
            const tokenItem = data.tokenData[i];
            const uniqueItem = data.uniqueData[i];
    
            const combinedItem = {
              date: tokenItem.date,
              usage: tokenItem.usage,
              ConNo: uniqueItem?.ConNo,
            };
    
            combinedData.push(combinedItem);
          }
    
          setComData(combinedData)
          setFilteredDataBot(combinedData)
        }
      }, [data])

  return (
    <div className='pb-5 responsive-side-nav-other px-3' style={{ backgroundColor: '#171725', height: '100%', minHeight: '100vh', }}>

         <h1 className='pt-4 mb-4 text-white'>Token Usage - {botname}</h1>

         <div >
          {/* <label className='fs-4 d-flex justify-content-center container text-center mb-3' style={{ height: '100%', color: '#FFFFFF' }} >Analytics</label> */}
          <label className='fs-4 d-flex justify-content-center container text-center mt-5 mb-5' style={{ height: '100%', color: '#FFFFFF' }} >Daily Token Usage</label>
          <div className='fs-4 d-flex justify-content-center  text-center mt-1 mb-5'>

            <div className="input-group mb-3 mx-2 " style={{ width: '400px' }}>
              <input type="text" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} className="form-control col-lg-6" placeholder="YYYY or YYYY-MM" aria-label="Recipient's username" aria-describedby="button-addon2" />
              <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => handleCreateGraph()}>Generate Graph</button>
            </div>

          </div>
          <div className='fs-4 col-12 d-flex justify-content-center container text-center mb-5 mt-3' style={{ color: '#FFFFFF' }}>
          
            {tokenDataGraph.length === 0 ? '' :
              <ResponsiveContainer width="99%" minHeight={minHeight}>
                <LineChart width={1000} height={500} data={tokenDataGraph} margin={{ top: 5, right: 20, bottom: 5, left: 10 }}>
                  <Line type="monotone" dataKey="usage" stroke="#8884d8" />
                  <XAxis dataKey="date"
                    minTickGap={10}
                    style={{ fontSize: '14px' }}
                  />
                  <YAxis style={{ fontSize: '14px' }} allowDataOverflow={true} />
                  <Tooltip content={<CustomTooltip />} />
                </LineChart>
              </ResponsiveContainer>
            }
          </div>

          <div className='row col-11 mx-auto mt-4 d-flex '>
        <Card style={{ backgroundColor: '#212529' }} className='  d-flex rounded-4'>
      <h3 className='fw-bolder col-12 d-flex justify-content-center container text-center pt-5 mb-4' style={{ color: '#FFFFFF' }}>Daily Token Usage</h3>

          <Card.Body className="d-flex flex-column" style={{}}>
            <div className='row'>
              <input className='fs-4 col-sm-12 col-lg-12 d-flex justify-content-center rounded-4  mt-4 text-center mb-3 ' type="text"
                placeholder="Search..."
                value={searchTermBot}
                onChange={handleSearchBot} />
              {/* <Dropdown ref={refBot} drop='down-centered' className='col-sm-4 col-lg-2 mt-4 text-start mb-3 ' show={isOpenBot} onClick={toggleDropdownBot}>
                <button className='btn btn-outline-warning fw-bolder mx-3 mt-2 ' style={{}} id="dropdown-basic">
                  Sort
                </button>

                <Dropdown.Menu >
                  <Dropdown.Item href="#">
                    <button className='btn fw-bolder px-0 mt-2' onClick={() => sortDataBot('asc', 'uniCon')}>
                      Asc Conversation
                    </button>
                  </Dropdown.Item>
                  <Dropdown.Item href="#">
                    <button className='btn fw-bolder px-0 mt-2' onClick={() => sortDataBot('desc', 'uniCon')}>
                      Desc Conversation
                    </button>
                  </Dropdown.Item>
                  <Dropdown.Item href="#">
                    <button className='btn fw-bolder px-0 mt-2' onClick={() => sortDataBot('asc', 'tokenUsage')}>
                      Asc Token Usage
                    </button>
                  </Dropdown.Item>
                  <Dropdown.Item href="#">
                    <button className='btn fw-bolder px-0 mt-2' onClick={() => sortDataBot('desc', 'tokenUsage')}>
                      Desc Token Usage
                    </button>
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown> */}
            </div>
            {
  filteredDataBot.length === 0 ? (
    apiload ? (
      <ThreeDots height="40" width="40" color="white" />
    ) : (
      <p className="col-12 d-flex justify-content-start text-start" style={{ color: '#FFFFFF', wordBreak: 'break-all' }}>No Data Available</p>
    )
  ) : (
    <div className="table-responsive">
      <table className="table table-bordered table-hover" style={{ color: '#FFFFFF', backgroundColor: 'transparent', minWidth: '500px', overflowX: 'scroll' }}>
        <thead>
          <tr>
            <th style={{ color: '#FFFFFF', borderColor: '#FFFFFF', backgroundColor: '#212529' }}>Sr. No.</th>
            <th style={{ color: '#FFFFFF', borderColor: '#FFFFFF', backgroundColor: '#212529' }}>Date</th>
            <th style={{ color: '#FFFFFF', borderColor: '#FFFFFF', backgroundColor: '#212529' }}>Tokens Used</th>
          </tr>
        </thead>
        <tbody>
          {itemsToDisplayDataBot.map((x, index) => (
            <tr key={index} style={{ color: '#FFFFFF', borderColor: '#FFFFFF', backgroundColor: '#212529' }}>
              <td style={{ wordBreak: 'break-all', color: '#FFFFFF', borderColor: '#FFFFFF', backgroundColor: '#212529' }}>{index+1}</td>
              <td style={{ wordBreak: 'break-all', color: '#FFFFFF', borderColor: '#FFFFFF', backgroundColor: '#212529' }}>{x.date}</td>
              <td style={{ wordBreak: 'break-all', color: '#FFFFFF', borderColor: '#FFFFFF', backgroundColor: '#212529' }}>Tokens Used: {abbrNum(x.usage, 2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
            <div className=' d-flex justify-content-center mt-3 fs-4'>
              <div
                onClick={() => handlePageChangeBot(currentPageBot - 1)}
                className={` ${currentPageBot === 1 ? 'disabled' : ''}`}
              >
                <BsFillArrowLeftCircleFill style={{ color: 'white' }} />
              </div>
              <p className='d-flex  justify-content-center  text-center mb-1 mt-1 mx-4' style={{ color: '#FFFFFF' }}>
                Page {currentPageBot}
              </p>
              <div
                onClick={() => handlePageChangeBot(currentPageBot + 1)}
                className={` ${endIndexBot >= filteredDataBot.length ? 'disabled' : ''}`}
              >
                <BsFillArrowRightCircleFill style={{ color: 'white' }} />
              </div>
            </div>

          </Card.Body>
        </Card>
      </div>
        </div>
        <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
         </div>
  )
}

export default TokenLeftClientBot