// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
    display:none;
    font-size: 50px;

  }
  /* .custom-file-input::before {
    content: 'Select PDF';
    display: inline-block;

    border: 1px solid #FFC107;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;

    cursor: pointer;
 
    font-weight: 200;
    color: #FFC107;
    font-size: 12pt;
  }
  .custom-file-input:hover::before {
    color: black;
    background-color:#FFC107;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  } */`, "",{"version":3,"sources":["webpack://./src/component/CreateBot/createbotcss.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,eAAe;;EAEjB;EACA;;;;;;;;;;;;;;;;;;;;;;KAsBG","sourcesContent":[".custom-file-input::-webkit-file-upload-button {\n    visibility: hidden;\n    display:none;\n    font-size: 50px;\n\n  }\n  /* .custom-file-input::before {\n    content: 'Select PDF';\n    display: inline-block;\n\n    border: 1px solid #FFC107;\n    border-radius: 3px;\n    padding: 5px 8px;\n    outline: none;\n    white-space: nowrap;\n\n    cursor: pointer;\n \n    font-weight: 200;\n    color: #FFC107;\n    font-size: 12pt;\n  }\n  .custom-file-input:hover::before {\n    color: black;\n    background-color:#FFC107;\n  }\n  .custom-file-input:active::before {\n    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
