import React,{useState,useEffect} from 'react'
import '../DashboardPage/sidenav.css'
import axios from 'axios';
import { ThreeDots, TailSpin } from 'react-loader-spinner';
import Cookies from 'js-cookie'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis,Tooltip, ResponsiveContainer ,Line,LineChart} from 'recharts';
import { ToastContainer, toast } from 'react-toastify';
import { FaCoins } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa";


function CustomTooltip2({ payload, label, active }) {
    if (active) {
      return (
        <div className="custom-tooltip " style={{ backgroundColor: '#FFFFFF', color: 'black' }} >
          <p className="label">{`${label}`}</p> <p> {`${payload[0].value} Tokens`}</p>
        </div>
      );
    }
    return null;
  }

const TokensLeftClient = () => {

  
      

    const decoded = Cookies.get('accessToken');
    const BACKEND = process.env.REACT_APP_BACKEND

    const[dataBot,setDataBot]=useState([])
    const [apiload, setApiload] = useState(false)
    const[tokenSum,setTokenSum] = useState([])
    const[tokenSumLoading,setTokenSumLoading] = useState([])
    const [minHeight, setMinHeight] = useState(500); 
    const[token,setToken] = useState([])

    const [inputDateToken, setInputDateToken] = useState('');
    const [filteredDataTokenG, setFilteredDataTokenG] = useState([]);
    const [totalTokenUsagePerDate, setTotalTokenUsagePerDate] = useState([]);

    const formatTick = (tick) => (tick.length > 15 ? `${tick.substring(0, 15)}...` : tick);
  
    const handleDateChangeToken = (e) => {
      setInputDateToken(e.target.value);
    };
  
    const handleSubmitToken = (totalTokenUsagePerDate) => {
      console.log("50----",totalTokenUsagePerDate)
      const filtered = totalTokenUsagePerDate.filter((item) => {
        const itemDate = item.date;
        return itemDate.startsWith(inputDateToken);
      });
      setFilteredDataTokenG(filtered);
      console.log("253----",filtered)
      if(filtered.length === 0 && tokenSumLoading === true){
        console.log("253--toast run--",filtered.length)
        toast.error("Data Doesn't exist for this date ")
      }
    };
    const handleSubmitTokenBtn = () => {
    
      const filtered = totalTokenUsagePerDate.filter((item) => {
        const itemDate = item.date;
        return itemDate.startsWith(inputDateToken);
      });
      setFilteredDataTokenG(filtered);
      console.log("69----")
      if(filtered.length === 0){
        console.log("71--toast run--",filtered.length)
        toast.error("Data Doesn't exist for this date ")
      }
    };

    function abbrNum(number, decPlaces) {

        decPlaces = Math.pow(10, decPlaces);
        var abbrev = ["k", "m", "b", "t"];
        for (var i = abbrev.length - 1; i >= 0; i--) {
          var size = Math.pow(10, (i + 1) * 3);
          if (size <= number) {
            number = Math.round(number * decPlaces / size) / decPlaces;
            if ((number == 1000) && (i < abbrev.length - 1)) {
              number = 1;
              i++;
            }
            number += abbrev[i];
            break;
          }
        } 
        return number;
      }

    useEffect(() => {
        setApiload(true)
        setTokenSumLoading(true)
        axios.post(`${BACKEND}api/tokensleftdash`, { decoded }, {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*'
        })
          .then(res => { setDataBot(res.data[0]);console.log(res.data,"---89") ; setApiload(false);if(res.data[0].length ===0){setTokenSumLoading(false)} } ).catch(err => {console.log(" error from mybots", err); setApiload(false);setTokenSumLoading(false)})
      }, [])


      useEffect(() => {
        setToken(dataBot)
        console.log('databot',dataBot)
      }, [dataBot])

      useEffect(() => {
       
        console.log("---54")
        if (token.length > 0) {
            console.log("---56")
          const newDataArray = token.map(item => {
            const tokensUsedSum = item.tokenData.reduce((tokensAccumulator, token) => {
              return tokensAccumulator + token.usage;
            }, 0);
    
            const uniqueConversationsSum = item.uniCon.reduce((conversationsAccumulator, conversation) => {
              return conversationsAccumulator + conversation.ConNo;
            }, 0);

            const tokenCostSum = Array.isArray(item.tokenCost)
            ? item.tokenCost.reduce((costAccumulator, conversation) => {
                return costAccumulator + (conversation.usage || 0);
              }, 0)
            : 0;
    
            return {
              bot: item.bot,
              sourcePdf: item.sourcePdf,
              sourceUrl: item.sourceUrl,
              tokenData: item.tokenData,
              uniCon: item.uniCon,
              username: item.username,
              totalTokensUsed: tokensUsedSum,
              totalTokensCost: tokenCostSum,
              totalUniqueConversations: uniqueConversationsSum,
              id:item.id,
            };
          });
          console.log(newDataArray,"---77 token sum")
          setTokenSum(newDataArray);
          setTokenSumLoading(false)
        }
    
      }, [token])

      useEffect(() => {
        console.log(tokenSum,"-----tokensum")
        const tokenUsageMap = {};
        tokenSum.forEach((item) => {
          item.tokenData.forEach((x) => {
            const { date, usage } = x;
            tokenUsageMap[date] = (tokenUsageMap[date] || 0) + usage;
          })
        });
        const tokenUsageArray = Object.entries(tokenUsageMap).map(([date, total]) => ({
          date,
          totalTokenUsage: total,
        }));
        setTotalTokenUsagePerDate(tokenUsageArray);
        handleSubmitToken(tokenUsageArray)
        console.log(tokenUsageArray,"--n-115 sum")
      }, [tokenSum]);

  return (
    <div className='pb-5 responsive-side-nav-other px-3' style={{ backgroundColor: '#171725', height: '100%', minHeight: '100vh', }}>
         <h1 className='pt-4 mb-4 text-white'>Tokens Used</h1>

         <div className='  col-11 mx-auto d-flex justify-content-lg-between justify-content-center flex-wrap  px-0'>

          <div class="my-2 col-lg-3  col-md-5 col-sm-6 col-12 px-2 " style={{ maxWidth: '278px', minWidth: '278px', border: 'none' }}>
            <div class="card " style={{ minHeight: '130px' }} >
              <div className="card-body row px-1 mx-2 " style={{ marginBottom: '10px' }}>
                <div className='col-7'>
                  <h4 class="card-title" style={{ fontSize: '23px' }}> {abbrNum(tokenSum.reduce((acc, curr) => acc + curr.totalTokensUsed, 0), 2)}</h4>
                  <h6 class="card-subtitle mb-2 mt-2 text-body-secondary">Total Tokens Used</h6>
                </div>
                <div className='col-4'>
                  <FaCoins style={{ fontSize: '60px', color: '#620B84' }} />
                </div>
              </div>
            </div>
          </div>

          <div class="my-2 col-lg-3  col-md-5 col-sm-6 col-12 px-2 " style={{ maxWidth: '278px', minWidth: '278px', border: 'none' }}>
            <div class="card " style={{ minHeight: '130px' }}>
              <div className="card-body row px-1 mx-2 " style={{ marginBottom: '10px' }}>
                <div className='col-7'>
                  <h4 class="card-title">  {tokenSum.reduce((acc, curr) => acc + curr.totalTokensCost, 0).toFixed(4)} $</h4>
                  <h6 class="card-subtitle mb-2 mt-2 text-body-secondary">Total Tokens Cost</h6>
                </div>
                <div className='col-4'>
                  <FaDollarSign style={{ fontSize: '60px', color: '#620B84' }} />
                </div>
              </div>
            </div>
          </div>


        </div>

         <div className='row col-11 mx-auto mt-4 d-flex '>

<Card style={{ backgroundColor: '#212529' }} className='  d-flex rounded-4'>
<h3 className='fw-bolder col-12 d-flex justify-content-center container text-center pt-4 ' style={{ color: '#FFFFFF' }}>Chatbots</h3>

<Card.Body className="d-flex flex-column">
  <h1 className="card-title">{}</h1>
  {
    tokenSum.length === 0 ?
      tokenSumLoading ? <div style={{}} className='d-flex justify-content-center text-center'><ThreeDots height="40" width="40" color="white"/></div> : 
      <p className='col-12 d-flex justify-content-start text-start' style={{ color: '#FFFFFF', wordBreak: 'break-all' }}>No Data Available</p>
    :
    <div className="table-responsive">
      <table className="table table-bordered table-hover" style={{ color: '#FFFFFF', backgroundColor: 'transparent', minWidth:'500px',overflowX:'scroll' }}>
        <thead>
          <tr>
            <th style={{ color: '#FFFFFF', borderColor: '#FFFFFF',backgroundColor:'#212529' }}>Sr. No.</th>
            <th style={{ color: '#FFFFFF', borderColor: '#FFFFFF',backgroundColor:'#212529' }}>Bot</th>
            <th style={{ color: '#FFFFFF', borderColor: '#FFFFFF',backgroundColor:'#212529' }}>Tokens Used</th>
            <th style={{ color: '#FFFFFF', borderColor: '#FFFFFF',backgroundColor:'#212529' }}>Tokens Cost ( $ )</th>
            <th style={{ color: '#FFFFFF', borderColor: '#FFFFFF',backgroundColor:'#212529' }}>Details</th>
          </tr>
        </thead>
        <tbody>
          {tokenSum.map((x,index) => (
            <tr key={x.id} style={{ color: '#FFFFFF', borderColor: '#FFFFFF',backgroundColor:'#212529' }}>
              <td style={{ wordBreak: 'break-all',color: '#FFFFFF', borderColor: '#FFFFFF',backgroundColor:'#212529' }}>{index+1}</td>
              <td style={{ wordBreak: 'break-all',color: '#FFFFFF', borderColor: '#FFFFFF',backgroundColor:'#212529' }}>{x.bot}</td>
              <td style={{ wordBreak: 'break-all',color: '#FFFFFF', borderColor: '#FFFFFF',backgroundColor:'#212529' }}>{abbrNum(x.totalTokensUsed, 2)}</td>
              <td style={{ wordBreak: 'break-all',color: '#FFFFFF', borderColor: '#FFFFFF',backgroundColor:'#212529' }}>{abbrNum(x.totalTokensCost, 2)} $</td>
              <td className='d-flex justify-content-center' style={{ wordBreak: 'break-all',color: '#FFFFFF', borderColor: '#FFFFFF',backgroundColor:'#212529' }}>
                <Link className='btn btn-primary px-3 mx-auto' to={`/tokenleftbotdash/${x.id}`} style={{  color: '#FFFFFF' }}>Details</Link>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
        <tr style={{ color: '#FFFFFF', borderColor: '#FFFFFF', backgroundColor: '#212529' }}>
          <td colSpan="2" style={{ fontWeight: 'bold', color: '#FFFFFF', borderColor: '#FFFFFF', backgroundColor: '#212529' }}>Total</td>
          <td style={{ wordBreak: 'break-all', color: '#FFFFFF', borderColor: '#FFFFFF', backgroundColor: '#212529' }}>
            {abbrNum(tokenSum.reduce((acc, curr) => acc + curr.totalTokensUsed, 0), 2)}
          </td>
          <td style={{ wordBreak: 'break-all', color: '#FFFFFF', borderColor: '#FFFFFF', backgroundColor: '#212529' }}>
            {abbrNum(tokenSum.reduce((acc, curr) => acc + curr.totalTokensCost, 0), 2)} $
          </td>
          <td style={{backgroundColor:'#212529'}}></td>
        </tr>
      </tfoot>
      </table>
    </div>
  }
  <div className='d-flex justify-content-center mt-3 fs-4'>
  </div>
</Card.Body>
</Card>
</div>

<div className='row mx-lg-5 mx-1 mt-4'>
    <div style={{  }} className='col-xl-6 col-12 px-1 d-flex'>


<div className='fs-4 col-12  container text-center  mt-4 py-4 rounded-4' style={{ color: '#FFFFFF',backgroundColor: '#212529' }}>
<h4 className='pt-4  mb-4 text-white'>Your top 3 most used chatbots</h4>
<div className='d-flex justify-content-center'>
        {tokenSum.length === 0 ?  tokenSumLoading ? <TailSpin height="40" width="40" color="white"/>:"" :
        <>
       
         <ResponsiveContainer width="99%" minHeight={minHeight}>
           <BarChart width={1000} height='100%'   margin={{ bottom: 100 }} data={[...tokenSum].sort((a, b) => b.totalTokensUsed - a.totalTokensUsed).slice(0, 3)}>
          <XAxis dataKey="bot" angle={-45} textAnchor="end"  tickFormatter={formatTick} tick={{ fontSize: 18 }} interval={0} />
          <YAxis />
          <Tooltip content={<CustomTooltip2 />} />
          <Bar dataKey="totalTokensUsed" fill="#8884d8" />
        </BarChart>
        </ResponsiveContainer>
          </>
        }
        </div>
      </div>
      </div>
      <div className='col-xl-6 col-12 px-1 py-4 mt-4 rounded-4' style={{ backgroundColor: '#212529' }}>
        <div style={{ backgroundColor: '#212529' }}>
        <h4 className='pt-4  mb-4 text-white text-center'>Daily token usage</h4>
      <div className='fs-4 d-flex justify-content-center  text-center mt-1 mb-5'>
        <div className="input-group mb-3 mx-2 " style={{ width: '400px' }}>
          <input type="text" value={inputDateToken} onChange={handleDateChangeToken} className="form-control col-lg-6" placeholder="YYYY-MM-DD, YYYY-MM, or YYYY" aria-label="Recipient's username" aria-describedby="button-addon2" />
          <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={handleSubmitTokenBtn}>Generate Graph</button>
        </div>
      </div>

      <div className='fs-4 col-12 d-flex justify-content-center container text-center mb-5 mt-3' style={{ color: '#FFFFFF' }}>
        {filteredDataTokenG.length === 0 ? tokenSumLoading ? <TailSpin height="40" width="40" color="white"/>: '' :
          <ResponsiveContainer width="99%" minHeight={minHeight}>
            <LineChart width={1000} height='100%' data={filteredDataTokenG} margin={{ top: 5, right: 20, bottom: 5, left: 10 }}>
              <Line type="monotone" dataKey="totalTokenUsage" stroke="#8884d8" />
              <XAxis dataKey="date"
                minTickGap={10}
                style={{fontSize:'14px'}}
              />
              <YAxis style={{fontSize:'14px'}} allowDataOverflow={true} />
              <Tooltip content={<CustomTooltip2 />} />
            </LineChart>
          </ResponsiveContainer>
        }
      </div>
      </div>
      </div>
      </div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />    </div>
  )
}

export default TokensLeftClient