// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-button {
    display: inline-flex;
    padding:7px;
    overflow: hidden;
    background-color: #FFFFFF;
  }
  
  .option {
    flex: 1 1;
    
    
    border: none;
    background-color: #FFFFFF;
    color: #620B84;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .option.selected {
    background-color: #620B84;
    color: #FFFFFF;
    margin:0px;
    padding:7px;
    width:100%;
 
  }
  

  @media screen and (min-width: 1621px) {

    .sectionwidth {
      margin-left:10%;
      margin-right: 10%;
    }
  }
  @media screen and (max-width: 1620px ) and (min-width:1501px) {

    .sectionwidth {
      margin-left:10%;
      margin-right: 10%;
    }
  }
  
  /* Styles for screens below 500 pixels */
  @media screen and (max-width: 1500px) {

    .sectionwidth {
      margin-left:10px;
      margin-right: 30px
    }
  }`, "",{"version":3,"sources":["webpack://./src/component/Pricing/toggle.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,WAAW;IACX,gBAAgB;IAChB,yBAAyB;EAC3B;;EAEA;IACE,SAAO;;;IAGP,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,UAAU;IACV,WAAW;IACX,UAAU;;EAEZ;;;EAGA;;IAEE;MACE,eAAe;MACf,iBAAiB;IACnB;EACF;EACA;;IAEE;MACE,eAAe;MACf,iBAAiB;IACnB;EACF;;EAEA,wCAAwC;EACxC;;IAEE;MACE,gBAAgB;MAChB;IACF;EACF","sourcesContent":[".toggle-button {\n    display: inline-flex;\n    padding:7px;\n    overflow: hidden;\n    background-color: #FFFFFF;\n  }\n  \n  .option {\n    flex: 1;\n    \n    \n    border: none;\n    background-color: #FFFFFF;\n    color: #620B84;\n    text-align: center;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .option.selected {\n    background-color: #620B84;\n    color: #FFFFFF;\n    margin:0px;\n    padding:7px;\n    width:100%;\n \n  }\n  \n\n  @media screen and (min-width: 1621px) {\n\n    .sectionwidth {\n      margin-left:10%;\n      margin-right: 10%;\n    }\n  }\n  @media screen and (max-width: 1620px ) and (min-width:1501px) {\n\n    .sectionwidth {\n      margin-left:10%;\n      margin-right: 10%;\n    }\n  }\n  \n  /* Styles for screens below 500 pixels */\n  @media screen and (max-width: 1500px) {\n\n    .sectionwidth {\n      margin-left:10px;\n      margin-right: 30px\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
