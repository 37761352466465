import React,{useState,useEffect} from 'react'
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios'
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs'
import Cookies from 'js-cookie'
import { HiChatBubbleLeftRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { ThreeDots, TailSpin } from 'react-loader-spinner';

const ConversationClient = () => {

  const BACKEND = process.env.REACT_APP_BACKEND
  const decoded = Cookies.get('accessToken');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filterHistory, setFilterHistory] = useState([]);
  const [filterHistoryLoading, setFilterHistoryLoading] = useState(false);
  const [history, setHistory] = useState('')
  const [sortedHistory, setSortedHistory] = useState([]);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [openAccordionIndexBot, setOpenAccordionIndexBot] = useState(null);
  const [searchTermPay, setSearchTermPay] = useState([]);
  const [dataBotId,setDataBotId]=useState([])
  const [ids,setIds]=useState([])
  const [dataHistory,setDataHistory]=useState('')
  const [dataHistoryLoading,setDataHistoryLoading]=useState(false)
  const [apiload, setApiload] = useState(false)

  useEffect(() => {
    setApiload(true)
    setDataHistoryLoading(true)
    axios.post(`${BACKEND}api/mybotsids`, { decoded }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
      .then(res => { setIds(res.data);console.log(res.data) ; setApiload(false) }).catch(err => {console.log(" error from mybots", err); setApiload(false);setDataHistoryLoading(false)})
  }, [])


  useEffect(() => {
    setApiload(true)
    setDataHistoryLoading(true)
        axios.post(`${BACKEND}api/mybots`, { decoded }, {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*'
        })
          .then(res => { setDataBotId(res.data);console.log(res.data) ; setApiload(false) }).catch(err => {console.log(" error from mybots", err); setApiload(false);setDataHistoryLoading(false)})
  }, [])

  useEffect(()=>{
    setDataHistoryLoading(true)
    if(ids.length !==0){
      console.log(ids,"ids")
       axios.post(`${BACKEND}api/historynumberget`, { decoded,dataBotId:ids }, {
           'Content-type': 'application/json',
           'Accept': 'application/json',
           'Access-Control-Allow-Origin': '*'
         }).then(res => { setDataHistory(res.data.total_count);console.log(res.data.total_count) ; setApiload(false);setDataHistoryLoading(false) }).catch(err => {console.log(" error from mybots", err); setApiload(false);setDataHistoryLoading(false)})
        }else{
          setDataHistoryLoading(false)
        }
         },[ids])

  const messageStyleSend = {
    backgroundColor: '#0070DA',
    fontSize: '14px',
    // color: '#FFFFFF',
    fontFamily: 'arial',
  };
  const messageStyleRec = {
    backgroundColor: '#3D4648',
    fontSize: '14px',
    // color: '#FFFFFF',
    fontFamily: 'arial',

  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  
  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(sortedHistory.length / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    setFilterHistory(history)
  }, [history])

  const handleSearchPay = (e) => {
    const input = e.target.value;
    setSearchTermPay(input);

    const filtered = history.filter((item) => {
      const regex = new RegExp(input, 'i');
      for (const key in item) {
        if (item.hasOwnProperty(key) && regex.test(item[key])) {
          return true;
        }
      }
      return false;
    });
    setFilterHistory(filtered);
  };

  useEffect(() => {
    const sortedData = Array.isArray(filterHistory)
      ? filterHistory.sort((a, b) => new Date(b.time) - new Date(a.time))
      : [];

    setSortedHistory(sortedData);
    setCurrentPage(1)
  }, [filterHistory]);


  const itemsToDisplay = sortedHistory.slice(startIndex, endIndex);
  console.log("Items to display:", itemsToDisplay);


  const toggleAccordion = (index) => {
    if (openAccordionIndex === index) {
      setOpenAccordionIndex(null);
    } else {
      setOpenAccordionIndex(index);
    }
  };

  const toggleAccordionBot = (index,id) => {
    if (openAccordionIndexBot === index) {
      setOpenAccordionIndexBot(null);

      setHistory([])
    } else {
      setOpenAccordionIndexBot(index);
      setFilterHistoryLoading(true)

      axios.post(`${BACKEND}api/historygetclientdash`,{id}, {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }).then(res => { if (res.data[0] == 'error') { console.log("321----", res.data);setFilterHistoryLoading(false) } else { setHistory(res.data);console.log(res.data);setFilterHistoryLoading(false)  } }).catch(err => {console.log(err);setFilterHistoryLoading(false)})
    }
  };


  return (
    <div className='pb-5 responsive-side-nav-other px-3' style={{ backgroundColor: '#171725', height: '100%', minHeight: '100vh', }}>
         <h1 className='pt-4 mb-4 text-white'>Chats</h1>

         <div class="my-2 col-xxl-3 col-xl-5 col-md-5 col-sm-6 col-12 px-2  mx-auto"  style={{maxWidth:'278px',minWidth:'278px',border:'none'}}>
<div class="card " style={{minHeight:'130px'}}>
  <div className="card-body row px-1 mx-2 " style={{marginBottom:'10px'}}>
    <div className='col-7'>
      {
        dataHistoryLoading ? <TailSpin height="40" width="40" color="#620B84"/>:
    <h1 class="card-title">{dataHistory.length !==0 ? dataHistory: 0}</h1>

      }
    <h6 class="card-subtitle mb-2 mt-2 text-body-secondary">Chats</h6>
    </div>
    <div className='col-4'>
    <HiChatBubbleLeftRight style={{fontSize:'60px', color:'#620B84'}}/>
    </div>
    </div>
  </div>
</div>


         <div className='d-flex justify-content-center  flex-wrap mt-2 mx-sm-5 mx-2'>
        {dataBotId?.map((x,index) => (
 <Accordion
 alwaysOpen={false}
 key={index}
 className='col-11 my-2 custom-accordion'
 activeKey={openAccordionIndexBot === index ? '0' : null}
 onSelect={() => toggleAccordionBot(index,x.id)}
>
 <Accordion.Item eventKey="0" style={{ backgroundColor: '#212529', border: '1px solid #4A5AB0' }}>
   <Accordion.Header>
    <span className='fw-bold'> {x.name}</span> 
     <span
       style={{
         color: '#FFE459',
         position: 'absolute',
         right: '25px',
         top: '50%',
         fontSize: '34px',
         transform: 'translateY(-50%)',
       }}
     >
       {openAccordionIndexBot === index ? '-' : '+'}
     </span>
   </Accordion.Header>
   <Accordion.Body style={{ color: 'white' }}>

   <div className=' d-flex justify-content-center fs-4'>
            <div
              onClick={() => handlePageChange(currentPage - 1)}
              className={` ${currentPage === 1 ? 'disabled' : ''}`}
            >
              <BsFillArrowLeftCircleFill style={{ color: 'white' }} />
            </div>
            <p className='d-flex  justify-content-center  text-center mb-1 mt-1 mx-4' style={{ color: '#FFFFFF' }}>
              Page {currentPage}
            </p>
            <div
              onClick={() => handlePageChange(currentPage + 1)}
              className={` ${endIndex >= filterHistory.length ? 'disabled' : ''}`}
            >
              <BsFillArrowRightCircleFill style={{ color: 'white' }} />
            </div>
          </div>   

   <div className='fs-4 col-12 row d-flex justify-content-center text-center  mx-1 ' style={{ color: '#FFFFFF' }}>
            <input className='fs-4 col-sm-8 col-lg-10 col-11 d-flex justify-content-center rounded-4  mt-2 text-center mb-3 ' type="text"
              placeholder="Search..."
              value={searchTermPay}
              onChange={handleSearchPay}
            />
            {filterHistory.length === 0
        ?  filterHistoryLoading === true ? <div className='d-flex justify-content-center'><ThreeDots className='text-center' height="40" width="40" color="white"/></div>: <div>No Chats Yet</div>
        : itemsToDisplay.map((x, index) => (
          <Accordion
            alwaysOpen={false}
            key={index}
            className='col-11 my-2 custom-accordion'
            activeKey={openAccordionIndex === index ? '0' : null}
            onSelect={() => toggleAccordion(index)}
          >
            <Accordion.Item eventKey="0" style={{ backgroundColor: '#212529', border: '1px solid #4A5AB0' }}>
              <Accordion.Header>
             {x.time} {x.Name} {x.Email} 
                <span
                  style={{
                    color: '#FFE459',
                    position: 'absolute',
                    right: '25px',
                    top: '50%',
                    fontSize: '34px',
                    transform: 'translateY(-50%)',
                  }}
                >
                  {openAccordionIndex === index ? '-' : '+'}
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ color: 'white' }}>
                {x.history.map((message, idx) => (
                  <div
                    key={idx}
                    className={`message ${message.sender === 'me' ? 'sent' : 'received'}`}
                    style={message.sender === 'me' ? messageStyleSend : messageStyleRec}
                  >
                    <p style={{ color: 'white' }} className={`my-1 ${message.sender === 'me' ? 'text-end' : 'text-start'}`}>
                      {message.text}
                    </p>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
          </div>

   </Accordion.Body>
 </Accordion.Item>
</Accordion>
     
        ))}
        </div>

         </div>
  )
}

export default ConversationClient