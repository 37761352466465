import React from 'react'
import VerticalNavDash from '../../component/DashboardPage/VerticalNavDash'
import ManageBotDash from '../../component/DashboardPage/ManageBotDash'

const ManageBotsDashPage = () => {
  return (
    <div>
    <VerticalNavDash/>
   <ManageBotDash/>
  </div>
  )
}

export default ManageBotsDashPage