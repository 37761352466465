
import React, { useEffect, useState, useRef } from 'react';
import '../../src/css/chatCss.css'
import axios from 'axios'
import { RotatingLines, ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import {HiSpeakerWave, HiSpeakerXMark} from 'react-icons/hi2'
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa'
import { BsStopCircle } from 'react-icons/bs'
import sendIcon from '../assets/send.webp'
import socketIO from 'socket.io-client';
// import WebSocket from "ws";
import decodeAudio from 'audio-decode';
import { useMediaRecorder } from 'react-media-recorder';


function TypingEffect({ text }) {
    return <div>{text}</div>;
    
}

// import React, { useState, useEffect } from 'react';







const ChatUIDe = (botID) => {

    const [audioBlob, setAudioBlob] = useState(null);
    const ws = useRef(null);

    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [chatbotMsg, setChatbotMsg] = useState('');
    const [suggestedPrompt, setSPrompt] = useState([])
    const [spromptHide, setSpromptHide] = useState(false)
    const [plan, setPlan] = useState('')
    const [mark, setMark] = useState(false)
    const [loading, setLoading] = useState(false);
    const [audioSub, setAudioSub] = useState(false)
    const [uniqueCon, setUniqueCon] = useState(1)
    const [now, setNow] = useState('')
    const[chkk,setChkk] = useState([])
    const[ck,setCk]=useState([])
    const[consecFail,setConsecFail]=useState(0)
    const[consecFailMsg,setConsecFailMsg]=useState([])
    const[chatUiDe,setChatUiDe]= useState('True')
    const [inputValueHide, setInputValueHide] = useState(false);
    const [rdn,setRdn] = useState(false)
    const [toggleStoreName,setToogleStoreName]= useState(false)
    const [storeName,setStoreName]= useState('')
    const [toggleStoreEmail,setToogleStoreEmail]= useState(false)
    const [storeEmail,setStoreEmail]= useState('')
    const[errorDetected,setErrorDetected]=useState(false)
    const BACKEND = process.env.REACT_APP_BACKEND
    const BACKENDWS = process.env.REACT_APP_BACKENDWS
    const [socket, setSocket] = useState(null);
    const [room, setRoom] = useState('');
    const [answerCount,setAnswerCount]= useState(0)
    const [thankName,setThankName]= useState(false)
    const [thankEmail,setThankEmail]= useState(false)

useEffect(() => {
    try{
    const newSocket = socketIO.connect(BACKENDWS
        , {
        transports: [ "websocket"],
        enabledTransports: [ "websocket"],
    },
    {secure:true},
    // ,{transports: ['wss'], enabledTransports: ['wss'],}
    );
    // console.log("----------------------------Socket connect front",BACKEND)
    setSocket(newSocket);
    newSocket.on("connect", () => {
        // console.log("-----------------Socket connected on the front end");
    });
    newSocket.on("error", (error) => {
        // console.error("--------------Socket error on the front end:", error);
    });
    return () => {
        if(newSocket.readyState === 1){  /** Remove if statement if error ,-------------------------- */
            newSocket.disconnect();
            // console.log("Socket DIS--connect front------return one--")
        }
    };
}catch(err){
console.log(err,"======try catch")
}
}, [inputValue]);

    const joinRoom = (roomName) => {
        if (socket) {
            try{
                socket.emit('join', { room: roomName });
                setRoom(roomName);
            }catch(err){
                console.log("ERR on join room frontend code == ",err)
            }
           
        }
    };

    const leaveRoom = () => {
        if (socket) {
            socket.emit('leave', { room });
            setRoom('');
        }
    };

    const test = () => {
        if (socket) {
            socket.emit('test');
        }
    };
    const testRoom = (roomName) => {
        if (socket) {
            socket.emit('testRoom', { room: roomName });
        }
    };

    const sendMessage = (message) => {
        if (socket) {
            socket.emit('message', { room, msg: message });
        } else {
            // console.log("no room joined")
        }
    };

    useEffect(() => {
        socket?.on('welcome_message', (data) => {
            // console.log('Received welcome message:', data.message);
        });
    }, [socket])

    useEffect(() => {
        socket?.on('message-chat', data => {
            setChkk(prevChkk => [...prevChkk, data.message])
        });
   
    }, [socket])

    const handleInputChange = async (e) => {
        test()
        testRoom("Roonmane")
        await setInputValue(e.target.value)
    };

    const handleSubmitP = (x) => {

        if (x.trim() === '') {
            return;
        }
        setSpromptHide(true)
        joinRoom(botID.botID)
        const newMessage = {
            id: messages.length + 1,
            text: x,
            sender: 'me',
        };
        let inputValue = x
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        if(toggleStoreName === true){
            const nameRegex = /[0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|~`]/;
            if (nameRegex.test(inputValue)) {
              toast.error("Name cannot contain numbers or symbols");
              return;
            }
            setStoreName(inputValue)
            setToogleStoreName(false)
        }else if(toggleStoreEmail === true){
            setStoreEmail(inputValue)
            setToogleStoreEmail(false)
        }
        else{
        
        setLoading(true);
        scrollToBottom()
        axios.post(`${BACKEND}api/msg`, { inputValue, botID, uniqueCon, chatUiDe }, {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        })
        .then(res => { 
            if (res.data === 'SubE') { setLoading(false); setUniqueCon(0); setChatbotMsg("Your services in the plan have expired. Kindly upgrade") }
            else if (res.data == 'noid') { setLoading(false);setUniqueCon(0);  setChatbotMsg("Sorry, This Bot has been deleted") }
            else if (res.data[0] == 'Some Error Occured !!!!') { setLoading(false);console.log("-------111111-----",res.data);setUniqueCon(0);  setChatbotMsg("Some Error Occured !!!5!"); setConsecFailMsg(prev => [...prev, res.data[1]]); setConsecFail(consecFail + 1) }
            else if (res.data == 'RDNF') { setLoading(false); setChatbotMsg("Relevant Data Not Found.");setUniqueCon(0);  setConsecFailMsg(prev => [...prev, res.data]); setConsecFail(consecFail + 1) }
            else if (res.data[1] == 'RDN') { setRdn(true);setLoading(false); setChatbotMsg(res.data[0]);setUniqueCon(0);  setConsecFailMsg(prev => [...prev, res.data]); setConsecFail(consecFail + 1) }
            else {setAnswerCount(answerCount+1); setChatbotMsg(res.data[0]); setConsecFail(0); setUniqueCon(0); setLoading(false) }
        })
            .catch(err => { setLoading(false); console.log(err); setConsecFail(consecFail + 1); setConsecFailMsg(prev => [...prev, err]); setChatbotMsg("Sorry, Some Error has Occured !!!! ") })
        scrollToBottom()
    }
    }

    useEffect(() => {
        if (uniqueCon === 1) {
            setNow(new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString())
        }
    }, [uniqueCon])

    useEffect(()=>{
        console.log("ip",inputValue)
    },[inputValue])

    const handleSubmit = async (e) => {
        setInputValueHide(true)
        if (e) {
            e.preventDefault();
        }
        joinRoom(botID.botID)
        setSpromptHide(true)
        if (inputValue.trim() === '') {
            setInputValueHide(false)
            return;
        }
        if (/^\s+$/.test(inputValue)) {
            // Handle the case where the input consists only of spaces
            toast.error("Input cannot consist only of spaces");
            return;
        } 
        if (/\s\s+/.test(inputValue)) {
            // Handle the case where there are consecutive spaces
            toast.error("Input cannot contain 2 consecutive spaces");
            setInputValueHide(false);
            return;
          }

        if(toggleStoreName === true){
            const nameRegex = /[0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|~`]/;
            if (nameRegex.test(inputValue)) {
              toast.error("Name cannot contain numbers or symbols");
              setInputValueHide(false)
              return;
            }
        }
        if(toggleStoreEmail === true){
            const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
            if (!emailRegex.test(inputValue)) {
              toast.error("Invalid Email Format");
              setInputValueHide(false)
              return;
            }           
        }

        const newMessage = {
            id: messages.length + 1,
            text: inputValue,
            sender: 'me',
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
      console.log("new message input value",inputValue)
        if(toggleStoreName === true){

            const nameRegex = /[0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|~`]/;
            if (nameRegex.test(inputValue)) {
              toast.error("Name cannot contain numbers or symbols");
              setInputValueHide(false)
              return;
            }

            setStoreName(inputValue)
            setToogleStoreName(false)
        }else if(toggleStoreEmail === true){
           
            setStoreEmail(inputValue)
            setToogleStoreEmail(false)
            setInputValueHide(false)
        }
        else{
       
        setLoading(true);
        scrollToBottom()
        console.log('262')
        // return 'Remove RETURN'
        console.log('264')

        axios.post(`${BACKEND}api/msg`, { inputValue, botID, uniqueCon, chatUiDe }, {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        })
            .then(res => { 
                if (res.data === 'SubE') {  setInputValueHide(false); setLoading(false); setChatbotMsg("Your services in the plan have expired. Kindly upgrade"); setUniqueCon(0) }
                 else if (res.data == 'noid') { setInputValueHide(false); setLoading(false); setChatbotMsg("Sorry, This Bot has been deleted"); setUniqueCon(0) } 
                 else if (res.data[0] == 'Some Error Occured !!!!') { setInputValueHide(false); setLoading(false); setChatbotMsg("Some Error Occured !!!!"); setConsecFail(consecFail + 1); setUniqueCon(0); setConsecFailMsg(prev => [...prev, res.data[1]]) } 
                 else if (res.data == 'RDNF') { setInputValueHide(false); setLoading(false); setChatbotMsg("Relevant Data Not Found."); setUniqueCon(0); setConsecFailMsg(prev => [...prev, "Relevant Data Not Found"]); setConsecFail(consecFail + 1) }
                else if (res.data[1] == 'RDN') { setRdn(true); setInputValueHide(false); setLoading(false); setChatbotMsg(res.data[0]); setUniqueCon(0); setConsecFailMsg(prev => [...prev, res.data]); setConsecFail(consecFail + 1) }
                else { setInputValueHide(false); setAnswerCount(answerCount+1);setChatbotMsg(res.data[0]);  setConsecFail(0); setUniqueCon(0); setLoading(false); }
            }).catch(err => { setInputValueHide(false); setLoading(false); setConsecFail(consecFail + 1); console.log(err); setChatbotMsg("Sorry, Some Error has Occured !!!! "); setConsecFailMsg(prev => [...prev, err]) })
        scrollToBottom()
        }
    };

    useEffect(() => {
        if (consecFail >= 1 && messages[messages.length - 1]['sender'] == '') {
            const failmsg = messages.filter(message => message.text !== '').slice(-2)
            const consecFailMsgF = consecFailMsg.slice(-1)
            setConsecFail(0);
            axios.post(`${BACKEND}api/consecFailure`, { botID, failmsg, consecFailMsgF }, {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            })
                .then(res => { setConsecFailMsg([]);  }).catch(err => console.log(err))
            // .then()
        }
    }, [messages])

    useEffect(() => {
        const filteredArray = messages.filter(item => item.text !== '');
        // const processedArray = filteredArray.map(({ id, ...x }) => x);
        let errorDet = false
        const processedArray = filteredArray.map(({ id, ...x }) => {
            if(x.sender !== 'me'){
              if(x.text ==='Some Error Occured !!!!' || x.text ==='Relevant Data Not Found.' || x.text ==='Sorry, Some Error has Occured !!!! ' || x.text ==="Some Error Occured !!!!" || x.text ==="Sorry, Some Error has Occured !!!! " || x.text ==="Your services in the plan have expired. Kindly upgrade" || x.text ==="Sorry, This Bot has been deleted" || rdn === true){
                 console.log("Error detected")
                 setErrorDetected(true)
                 errorDet = true
              }
            }
             return x;
            });

            console.log("error Detected",errorDet)
        if(storeName !== ''){
            setThankName(true)
        }
        if(storeEmail !== ''){
            setThankEmail(true)
        }
        if(now ==''){
            return
        }
        axios.post(`${BACKEND}api/history`, { botID, processedArray, now, uniqueCon,storeName,storeEmail,errorDetected:errorDet }, {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }).then(res => { setStoreName('');setStoreEmail('');setErrorDetected(false);setRdn(false)}).catch(err => {console.log(err);setErrorDetected(false);setRdn(false)})
    }, [chatbotMsg,storeName,storeEmail])

    const[apiload,setApiload] = useState(false)
    

    useEffect(() => {
        setApiload(true)
        axios.get(`${BACKEND}api/fontdata/${botID.botID}`)
        .then(res =>{if(res.data[0]=='error'){console.log(res.data[1]);toast.error("Some Error Occured"); setApiload(false)}
         else{ setFontData(res.data);
            //  setSPrompt(res.data.sPrompt);
              setChatbotMsg(res.data.initialMsg); setPlan(res.data.plan); setApiload(false) }})
         .catch(err => {console.log(err); setApiload(false)})
    }, [botID.botID])


    useEffect(() => {
        setChkk([])
        const newMessage = {
            id: messages.length + 1,
            text: chatbotMsg,
            sender: '',
        };
        if (chatbotMsg === '' && chatbotMsg === null ) {
            // console.log("W")
        } else {
         
                setMessages((prevMessages) => [...prevMessages, newMessage]);
            
         
            // if (speechTog === true) {
            //     const speechSynthesis = window.speechSynthesis;
            //     const utterance = new SpeechSynthesisUtterance(chatbotMsg);
            //     speechSynthesis.speak(utterance);
            // }

            if (speechTog === true) {
                const speechSynthesis = window.speechSynthesis;
                const maxChunkLength = 100; 
                let textToSpeak = chatbotMsg;
              
                while (textToSpeak.length > maxChunkLength) {
                  let lastSpaceIndex = textToSpeak.lastIndexOf(' ', maxChunkLength);
                  if (lastSpaceIndex === -1) {
                    lastSpaceIndex = maxChunkLength;
                  }
                  
                  const chunk = textToSpeak.substr(0, lastSpaceIndex);
                  const utterance = new SpeechSynthesisUtterance(chunk);
                  speechSynthesis.speak(utterance);
              
                  textToSpeak = textToSpeak.substr(lastSpaceIndex + 1);
                }

                if (textToSpeak) {
                  const utterance = new SpeechSynthesisUtterance(textToSpeak);
                  speechSynthesis.speak(utterance);
                }
              }
            // console.log("speech tog  is set to ", speechTog)

        }
        setInputValue('');
        setChatbotMsg('')
        // console.log("lllllllllllll")
        leaveRoom()
        scrollToBottom()
    }, [chatbotMsg])

    const [speechTog, setSpeechTog] = useState(false)

    useEffect(()=>{
        const checkSpeech = setInterval(() => {
            if (speechTog === false) {
                // If speechTog is false, cancel the speech
                speechSynthesis.pause();
                speechSynthesis.cancel();
                // console.log("cancel")
                clearInterval(checkSpeech); // Stop the loop
            }
        }, 100);
    },[speechTog])


    useEffect(() => {
        if (plan === 'year-enterprise' || plan === 'month-enterprise') {
            setMark(false)
        } else {
            setMark(true)
        }
        // console.log("mark = ", mark, " and plan ", plan)
    }, [plan])


    const [fontData, setFontData] = useState({
        font: 'arial',
        userFontColor: '#0070DA',
        userFontTextColor: '#FFFFFF',
        cpuFontColor: '#3D4648',
        cpuFontTextColor: '#FFFFFF',
        backgroundColor: '#242439',
        fontSize: '12px',
    });

    const handleFontChange = (event) => {
        const { name, value } = event.target;
        if (name === 'fontSize') {
            // Prevent font size from being negative
            const fontSize = parseInt(value, 10);
            if (fontSize >= 0 && fontSize <= 32) {
                setFontData((prevData) => ({ ...prevData, [name]: fontSize }));
            }
        } else {
            setFontData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    const handleFontSubmit = (event) => {
        event.preventDefault();

        setLoading(true);
        axios.put(`${BACKEND}api/fontdata/${botID.botID}`, { fontData }, {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }).then(res => { if (res.data === 'Yes') { toast.success('Font Changes were successful'); setLoading(false); } else { toast.success('Some error occured'); setLoading(false); } }).catch((err) => { console.log("error manage bots ", err); toast.error('API request failed!'); setLoading(false); })
        // console.log(fontData);
    };

    const messageStyleSend = {
        backgroundColor: fontData.userFontColor,
        fontSize: fontData.fontSize,
        color: fontData.userFontTextColor,
        fontFamily: fontData.font


    };
    const messageStyleRec = {
        backgroundColor: fontData.cpuFontColor,
        fontSize: fontData.fontSize,
        color: fontData.cpuFontTextColor,
        fontFamily: fontData.font

    };

    const fontOptions = [
        'Arial',
        'Times New Roman',
        'Helvetica',
        'Courier New',
        'Verdana',
    ];

    // const handleAudio=()=>{

    // }   

    const [permission, setPermission] = useState(false);
    const [stream, setStream] = useState(null);
    const mediaRecorder = useRef(null);
    const [recordingStatus, setRecordingStatus] = useState("inactive");
    const [audioChunks, setAudioChunks] = useState([]);
    const [audio, setAudio] = useState(null);
    // const [speechTog, setSpeechTog] = useState(false)

    const getMicrophonePermission = async () => {
        if ("MediaRecorder" in window) {
            try {
                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setPermission(true);
                setStream(streamData);
            } catch (err) {
                alert(err.message);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    }

    // const mimeType = "audio/webm";

    // const startRecording = async () => {
    //     setRecordingStatus("recording");
    //     // Get the audio stream from the user's microphone
    //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    //     const media = new MediaRecorder(stream, { mimeType });
    //     mediaRecorder.current = media;

    //     mediaRecorder.current.start();
    //     let localAudioChunks = [];
    //     mediaRecorder.current.ondataavailable = (event) => {
    //         if (event.data.size > 0) {
    //             localAudioChunks.push(event.data);
    //         }
    //     };
    //     setAudioChunks(localAudioChunks);
    // };

    // const stopRecording = () => {
    //     setRecordingStatus("inactive");
    //     mediaRecorder.current.stop();
    //     mediaRecorder.current.onstop = async () => {
    //         const audioBlob = new Blob(audioChunks, { type: mimeType });
    //         setLoading(true);
    //         setAudio(audioBlob);
    //         setAudioChunks([]);
    //     };
    // };

    // useEffect(() => {
    //     if (audio !== null) {
    //         const sendAudioToBackend = async () => {
    //             const formData = new FormData();
    //             formData.append('audio', audio, 'recording.webm');

    //             try {
    //                 const response = await axios.post(`${BACKEND}voice/audio`, formData, {
    //                     headers: {
    //                         'Content-Type': 'multipart/form-data', // Correct content type
    //                         'Accept': 'application/json',
    //                     }
    //                 });
    //                 // Handle the response from your backend
    //                 console.log('Response:', response.data);
    //                 setInputValue(response.data);setLoading(false); setAudioSub(true); setAudio(null)
    //             } catch (error) {
    //                 console.error('Error sending audio:', error);
    //             } finally {
    //                 setLoading(false);
    //                 setAudio(null); // Reset audio state
    //             }
    //         };

    //         sendAudioToBackend();
    //     }
    // }, [audio]);










//     const mimeType = "audio/webm";

//     const API_KEY = process.env.REACT_APP_OPENAI_API_KEY; // Replace with your actual API key
// const MODEL = 'gpt-4o-realtime-preview-2024-10-01';
// const WS_BASE_URL = 'wss://api.openai.com/v1/realtime';

//     // WebSocket connection
//     const initializeWebSocket = () => {
//         const token = process.env.REACT_APP_OPENAI_API_KEY;
//         const url = `${WS_BASE_URL}?model=${encodeURIComponent(MODEL)}&Authorization=Bearer ${encodeURIComponent(API_KEY)}&OpenAI-Beta=realtime=v1`;
//        ws.current = new WebSocket(
//             'wss://api.openai.com/v1/realtime?model=gpt-4o-realtime-preview-2024-10-01',
//             [],
//             {
//               finishRequest: (request) => {
//                 // Auth
//                 request.setHeader('Authorization', `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`);
//                 request.setHeader('OpenAI-Beta', 'realtime=v1');
//                 request.end();
//               },
//             }
//           );
        
//         // ws.current = new WebSocket(url);

    
//         ws.current.onopen = () => {
//             console.log("Connected to server.");
    
//             // Send authorization message
//             const authMessage = {
//                 type: "response.create",
//                 response: {
//                     modalities: ["text"],
//                     instructions: "Please assist the user.",
//                     headers: {
//                         "Authorization": "Bearer " + process.env.REACT_APP_OPENAI_API_KEY,
//                         "OpenAI-Beta": "realtime=v1",
//                     },
//                 },
//             };
//             ws.current.send(JSON.stringify(authMessage));
//         };
    
//         ws.current.onmessage = (event) => {
//             const message = JSON.parse(event.data);
//             console.log(message);
//             if (message.type === 'error') {
//                 console.error("WebSocket Error:", message.error);
//             }
//         };
    
//         ws.current.onerror = (error) => {
//             console.error("WebSocket Error:", error);
//         };
    
//         ws.current.onclose = () => {
//             console.log("WebSocket connection closed.");
//         };
//     };
    
//     const startRecording = async () => {
//         setRecordingStatus("recording");
//         try {
//             const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//             const media = new MediaRecorder(stream, { mimeType });
//             mediaRecorder.current = media;
    
//             mediaRecorder.current.ondataavailable = (event) => {
//                 if (event.data.size > 0) {
//                     console.log("Audio data available:", event.data.size);
//                     setAudioChunks((prev) => [...prev, event.data]);
//                 } else {
//                     console.warn("No audio data in event:", event);
//                 }
//             };
    
//             mediaRecorder.current.onerror = (error) => {
//                 console.error("MediaRecorder Error:", error);
//             };
    
//             mediaRecorder.current.start();
//         } catch (error) {
//             console.error("Error accessing microphone:", error);
//         }
//     };
    
//     const stopRecording = async () => {
//         setRecordingStatus("inactive");
        
//         // Check if the mediaRecorder is initialized before stopping
//         if (mediaRecorder.current) {
//             mediaRecorder.current.stop();
//             mediaRecorder.current.onstop = async () => {
//                 // Check the length of audioChunks
//                 if (audioChunks.length === 0) {
//                     console.error("No audio data to process");
//                     return; // No audio to process
//                 }
    
//                 const audioBlob = new Blob(audioChunks, { type: mimeType });
//                 setAudioBlob(audioBlob); // Set the final audio blob
//                 setAudioChunks([]); // Reset chunks after processing
    
//                 // Convert to ArrayBuffer
//                 const arrayBuffer = await audioBlob.arrayBuffer();
    
//                 // Log the ArrayBuffer length
//                 console.log("ArrayBuffer length:", arrayBuffer.byteLength);
    
//                 try {
//                     const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//                     const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
//                     const channelData = audioBuffer.getChannelData(0);
    
//                     // Convert Float32Array to PCM16
//                     const base64Chunk = base64EncodeAudio(channelData);
    
//                     // Send audio data over WebSocket
//                     if (ws.current && ws.current.readyState === WebSocket.OPEN) {
//                         ws.current.send(JSON.stringify({
//                             type: 'input_audio_buffer.append',
//                             audio: base64Chunk,
//                         }));
//                         ws.current.send(JSON.stringify({ type: 'input_audio_buffer.commit' }));
//                         ws.current.send(JSON.stringify({ type: 'response.create' }));
//                     } else {
//                         console.warn("WebSocket is not open. Current state:", ws.current?.readyState);
//                     }
//                 } catch (error) {
//                     console.error("Error decoding audio data:", error);
//                 }
//             };
//         } else {
//             console.warn("MediaRecorder is not initialized.");
//         }
//     };
    
//     const base64EncodeAudio = (float32Array) => {
//         const buffer = floatTo16BitPCM(float32Array);
//         let binary = '';
//         const bytes = new Uint8Array(buffer);
//         const chunkSize = 0x8000; // 32KB chunk size
//         for (let i = 0; i < bytes.length; i += chunkSize) {
//             const chunk = bytes.subarray(i, i + chunkSize);
//             binary += String.fromCharCode.apply(null, chunk);
//         }
//         return btoa(binary);
//     };
    
//     const floatTo16BitPCM = (float32Array) => {
//         const buffer = new ArrayBuffer(float32Array.length * 2);
//         const view = new DataView(buffer);
//         let offset = 0;
//         for (let i = 0; i < float32Array.length; i++, offset += 2) {
//             const s = Math.max(-1, Math.min(1, float32Array[i]));
//             view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
//         }
//         return buffer;
//     };
    
//     // Initialize WebSocket connection on component mount
//     useEffect(() => {
//         initializeWebSocket();
    
//         // Cleanup WebSocket connection on component unmount
//         return () => {
//             if (ws.current) {
//                 ws.current.close();
//             }
//         };
//     }, []);











    // const mimeType = "audio/webm";

    // // WebSocket connection
    // const initializeWebSocket = () => {
    //     const url = "wss://api.openai.com/v1/realtime?model=gpt-4o-realtime-preview-2024-10-01";
    //     ws.current = new WebSocket(url);

    //     ws.current.onopen = () => {
    //         console.log("Connected to server.");

    //         // Send authorization message
    //         const authMessage = {
    //             type: "response.create",
    //             response: {
    //                 modalities: ["text"],
    //                 instructions: "Please assist the user.",
    //                 headers: {
    //                     "Authorization": "Bearer " + process.env.REACT_APP_OPENAI_API_KEY,
    //                     "OpenAI-Beta": "realtime=v1",
    //                 },
    //             },
    //         };
    //         ws.current.send(JSON.stringify(authMessage));
    //     };

    //     ws.current.onmessage = (event) => {
    //         const message = JSON.parse(event.data);
    //         console.log(message);
    //         if (message.type === 'error') {
    //             console.error("WebSocket Error:", message.error);
    //         }
    //     };

    //     ws.current.onerror = (error) => {
    //         console.error("WebSocket Error:", error);
    //     };

    //     ws.current.onclose = () => {
    //         console.log("WebSocket connection closed.");
    //     };
    // };

    // const startRecording = async () => {
    //     setRecordingStatus("recording");
    //     try {
    //         const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    //         const media = new MediaRecorder(stream, { mimeType });
    //         mediaRecorder.current = media;

    //         mediaRecorder.current.ondataavailable = (event) => {
    //             if (event.data.size > 0) {
    //                 console.log("Audio data available:", event.data.size);
    //                 setAudioChunks((prev) => [...prev, event.data]);
    //             }
    //         };

    //         mediaRecorder.current.start();
    //     } catch (error) {
    //         console.error("Error accessing microphone:", error);
    //     }
    // };

    // const stopRecording = async () => {
    //     setRecordingStatus("inactive");
    //     mediaRecorder.current.stop();
    //     mediaRecorder.current.onstop = async () => {
    //         if (audioChunks.length === 0) {
    //             console.error("No audio data to process");
    //             return; // No audio to process
    //         }
    
    //         const audioBlob = new Blob(audioChunks, { type: mimeType });
    //         setAudioBlob(audioBlob); // Set the final audio blob
    //         setAudioChunks([]); // Reset chunks after processing
    
    //         // Convert to ArrayBuffer
    //         const arrayBuffer = await audioBlob.arrayBuffer();
    
    //         // Log the ArrayBuffer length
    //         console.log("ArrayBuffer length:", arrayBuffer.byteLength);
    
    //         try {
    //             const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    //             const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    //             const channelData = audioBuffer.getChannelData(0);
    
    //             // Convert Float32Array to PCM16
    //             const base64Chunk = base64EncodeAudio(channelData);
    
    //             // Send audio data over WebSocket
    //             if (ws.current) {
    //                 ws.current.send(JSON.stringify({
    //                     type: 'input_audio_buffer.append',
    //                     audio: base64Chunk,
    //                 }));
    //                 ws.current.send(JSON.stringify({ type: 'input_audio_buffer.commit' }));
    //                 ws.current.send(JSON.stringify({ type: 'response.create' }));
    //             }
    //         } catch (error) {
    //             console.error("Error decoding audio data:", error);
    //         }
    //     };
    // };
    

    // const base64EncodeAudio = (float32Array) => {
    //     const buffer = floatTo16BitPCM(float32Array);
    //     let binary = '';
    //     const bytes = new Uint8Array(buffer);
    //     const chunkSize = 0x8000; // 32KB chunk size
    //     for (let i = 0; i < bytes.length; i += chunkSize) {
    //         const chunk = bytes.subarray(i, i + chunkSize);
    //         binary += String.fromCharCode.apply(null, chunk);
    //     }
    //     return btoa(binary);
    // };

    // const floatTo16BitPCM = (float32Array) => {
    //     const buffer = new ArrayBuffer(float32Array.length * 2);
    //     const view = new DataView(buffer);
    //     let offset = 0;
    //     for (let i = 0; i < float32Array.length; i++, offset += 2) {
    //         const s = Math.max(-1, Math.min(1, float32Array[i]));
    //         view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    //     }
    //     return buffer;
    // };


    // // Initialize WebSocket connection on component mount
    // useEffect(() => {
    //     initializeWebSocket();

    //     // Cleanup WebSocket connection on component unmount
    //     return () => {
    //         if (ws.current) {
    //             ws.current.close();
    //         }
    //     };
    // }, []);





    // const mimeType = "audio/webm";
    // const startRecording = async () => {
    //     setRecordingStatus("recording");
    //     //create new Media recorder instance using the stream
    //     const media = new MediaRecorder(stream, { type: mimeType });
    //     //set the MediaRecorder instance to the mediaRecorder ref
    //     mediaRecorder.current = media;
    //     //invokes the start method to start the recording process
    //     mediaRecorder.current.start();
    //     let localAudioChunks = [];
    //     mediaRecorder.current.ondataavailable = (event) => {
    //         if (typeof event.data === "undefined") return;
    //         if (event.data.size === 0) return;
    //         localAudioChunks.push(event.data);
    //     };
    //     setAudioChunks(localAudioChunks);
    // };
    // const stopRecording = () => {
    //     setRecordingStatus("inactive");
    //     //stops the recording instance
    //     mediaRecorder.current.stop();
    //     mediaRecorder.current.onstop = () => {
    //         //creates a blob file from the audiochunks data
    //         const audioBlob = new Blob(audioChunks, { type: mimeType });

    //         setLoading(true)
    //         setAudio(audioBlob);
            
    //         setAudioChunks([]);
    //     };
    // };

    // const mimeType = "audio/webm"

    // useEffect(() => {
       
    //     console.log("538------------",loading)
    //     if (audio !== null) {
    //         setLoading(true)
    //         const formData = new FormData();
    //         formData.append('audio', audio, 'recording.webm');
    //         for (let pair of formData.entries()) {
    //             // console.log(pair[0] + ', ' + pair[1]);
    //         }
    //         scrollToBottom()
    //         axios.post(`${BACKEND}api/audio`, formData, {
    //             'Content-type': 'application/json',
    //             'Accept': 'application/json',
    //             'Access-Control-Allow-Origin': '*'
    //         }).then(res => {  setInputValue(res.data);setLoading(false); setAudioSub(true); setAudio(null) }).catch(err => {console.log(err);setLoading(false)})
    //     }
        
    // }, [audio])

    useEffect(() => {
        if (audioSub === true) {
            handleSubmit()
            setInputValue('')
        }
        setAudioSub(false)
    }, [audioSub])

    const handleToggleSpeech = () => {
        setSpeechTog((prevTog) => !prevTog);
    };

    const chatContainerRef = useRef(null);
    const scrollToBottom = () => {
        setTimeout(() => {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }, 10);
    };

    const [duplicatePreventer1,setDuplicatePreventer1]=useState(false)
    const [duplicatePreventer2,setDuplicatePreventer2]=useState(false)

    useEffect(()=>{
   if(answerCount === 1 && duplicatePreventer1 === false && thankName !== true){
 
    const newMessage = {
        id: messages.length + 2,
        text: 'Tell me your name to proceed further',
        sender: '',
    };
            setMessages((prevMessages) => [...prevMessages, newMessage]);
            setToogleStoreName(true)
            setDuplicatePreventer1(true)
            
   }

   if(thankName === true ){
    setToogleStoreName(false)
            setThankName(false)
           

    const newMessage = {
        id: messages.length + 1,
        text: 'Thanks, You can proceed with your queries',
        sender: '',
    };
            setMessages((prevMessages) => [...prevMessages, newMessage]);
            setInputValue('')
            setInputValueHide(false)
   }
  

   if(answerCount === 5 && duplicatePreventer2 === false && thankEmail !== true){
 
    const newMessage = {
        id: messages.length + 2,
        text: 'Tell me your email to proceed further',
        sender: '',
    };
            setMessages((prevMessages) => [...prevMessages, newMessage]);
            setToogleStoreEmail(true)
            setDuplicatePreventer2(true)
            
   }

   if(thankEmail === true ){
    setToogleStoreEmail(false)
            setThankEmail(false)
    const newMessage = {
        id: messages.length + 1,
        text: 'Thanks, You can proceed with your queries',
        sender: '',
    };
            setMessages((prevMessages) => [...prevMessages, newMessage]);
            setInputValue('')
            setInputValueHide(false)
   }

    },[answerCount,thankName,thankEmail])








///////////////////////////////////////// real

const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [base64Audio, setBase64Audio] = useState(null); // State for base64 audio
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const startRecording = async () => {
    console.log('start recording')
    setRecordingStatus("recording");
    setIsRecording(true);
    audioChunksRef.current = []; // Reset audio chunks

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        uploadAudio(audioBlob); // Call function to upload audio to your backend
      };

      mediaRecorderRef.current.start();
      console.log('start recording 2')
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    console.log('start end')
    setRecordingStatus("inactive");
    setIsRecording(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  const uploadAudio = async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'recording.wav');

 
      const response = await fetch(`https://app.aivirtualops.com/api/send-audio`, {
        method: 'POST',
        body: formData,
      })
      .then((response) => response.json())
      .then((data) => {
        const { transcript, audio } = data;
    
        // Display the transcript
        console.log("Transcript:", transcript);
        setChatbotMsg(transcript)
    
        // Convert base64 audio to Blob and play it
        const audioBlob = new Blob([Uint8Array.from(atob(audio), c => c.charCodeAt(0))], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audioElement = new Audio(audioUrl);
        audioElement.play();
      })
      .catch((error) => {
        console.error('Error fetching audio:', error);
      });}


///////////////////////////////////////// real


    return (
        <div className='row d-flex justify-content-around flex-wrap'>
            {/* ##################################### Chat UI DEMO */}
            <h1 className='fw-bolder py-3 rounded-4 px-1 col-lg-10 col-11  container text-center mb-0' style={{ color: '#FFFFFF',backgroundColor:'#242439' }}>This chatbot's trained to answer questions about <span className="gradient-text"> Zema</span>.
            <br/>Ask a question to get started.</h1>
            <div className='d-flex rounded-2 justify-content-center mt-5 mx-0 px-0 mb-4 col-lg-10 col-11 pt-0 pb-1' style={{backgroundColor:'white'}}>
                <div className='d-flex ' style={{ position: 'relative', height: '600px', minWidth: '240px', maxWidth: '90vw', width: '100%', marginTop:'0px',  }}>
                    {/* {
                        mark ?
                            <h1 className='bg-primary mx-3' style={{ position: 'absolute', opacity: '15%', left: '20px', bottom: '50px' }}>Powered by Zema</h1> : <p></p>
                    } */}
                    <div className="chat-container  px-0" ref={chatContainerRef}  style={{ backgroundColor: fontData.backgroundColor, height: '600px', paddingBottom: '100px', maxWidth: '90vw', minWidth: '240px', width: '100%', borderWidth: '0px',borderTopRightRadius:'15px', borderTopLeftRadius:'15px' }}>
                        <div className="chat-messages " >
                            {messages.map((message) => (
                                message.text === '' || null || undefined || messages[messages.length-1].text == messages[messages.length-2].text ? '' :
                                    <div
                                        key={message.id}
                                        className={`message ${message.sender === 'me' ? 'sent' : 'received'}`}
                                        style={message.sender === 'me' ? messageStyleSend : messageStyleRec}
                                    >
                                        {/* <div id="text-container" className="message-content typing">{message.text}</div> */}
                                        <TypingEffect id="text-container" className="message-content " text={message.text} />
                                    </div>
                            ))}
                             {
                                chkk.length == 0 ? '':<p style={ messageStyleRec} className="message  received" >{chkk}</p>
           }
             <div className='form-group d-flex justify-content-center mt-4'>
       {loading ? (
       <>
          <ThreeDots type="Oval" position="top-center" color={fontData.cpuFontColor} height={50} width={50} />

          </>
        
        ) : (
          ''
        )}
        </div>
                            {apiload ? (
                                <div className='d-flex flex-wrap justify-content-center mx-auto my-auto' >
                                <RotatingLines type="Oval" className='col-12'   color="#3D4648" height={50} width={50} />
                                <h3 className='col-12 mx-auto text-center mt-3' style={{ color:"#4C9E4B"}}>Please wait, we are getting chatbot ready for you!!!</h3>
</div>
                            ) : (
                                ''
                            )}
                        </div>
                        {/* #######################3 suggested prompts */}
                        <div style={{ position: 'absolute', left: '20px', bottom: '50px', width: '75%', maxWidth:'350px' }}>
                            {suggestedPrompt === undefined || spromptHide === true ? '' : suggestedPrompt.map(x => (
                                <div className='received message' onClick={() => { handleSubmitP(x) }} value={x} style={{ backgroundColor: fontData.cpuFontColor, fontSize: fontData.fontSize, color: fontData.cpuFontTextColor, fontFamily: fontData.font, width: '75%' }}  >{x}</div>
                            ))}
                        </div>
                    </div>
                    <form className="chat-input mt-5 rounded-bottom-4 " style={{ minHeight: '50px', height: '50px', maxWidth: '90vw', minWidth: '240px', width: '100%', bottom: '-2px', position: 'absolute', backgroundColor: fontData.backgroundColor, borderColor: 'black', borderWidth: '2px' }} onSubmit={(e) => handleSubmit(e)}>
                    {/* <h1 style={{color:'white'}}>{answerCount}</h1> */}
                        <input
                            type="text"
                            style={{ minWidth: '50%' }}
                            value={inputValueHide ? '' : inputValue}
                            onChange={handleInputChange}
                            placeholder="Ask me here about Zema..."
                        />
                        {/* Text to speech */}
                        <div className="me-2">
                            {
                                speechTog === false ?
                                    <button onClick={handleToggleSpeech} type="button">
                                        <HiSpeakerXMark />
                                    </button> :
                                    <button onClick={handleToggleSpeech} type="button">
                                        <HiSpeakerWave />
                                    </button>
                            }
                        </div>

{/* speech to text */}
                        <div className="audio-controls me-2">
                            {!permission ? (
                                <button onClick={getMicrophonePermission} type="button">
                                    <FaMicrophoneSlash />
                                </button>
                            ) : null}
                            {permission && recordingStatus === "inactive" ? (
                                <button onClick={startRecording} type="button">
                                    <FaMicrophone />
                                </button>
                            ) : null}
                            {recordingStatus === "recording" ? (
                                <button onClick={stopRecording} type="button">
                                    <BsStopCircle />
                                </button>
                            ) : null}
                        </div>

                        <button type="submit" ><img src={sendIcon} alt='Send' style={{ height: '22px', width: '25px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} /></button>
                        {/* <button type="submit" ><img src="https://icons.veryicon.com/png/o/internet--web/iview-3-x-icons/ios-send.png" style={{ height:'25px', width:'40px', backgroundSize:'contain', backgroundRepeat:'no-repeat'}}/></button> */}
                    </form>
                    {/* {audio ? (
  <div className="audio-container">
     <audio src={audio} controls></audio>
     <a download href={audio}>
        Download Recording
     </a>
   </div>
) : null} */}
                </div>
            </div>
           
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
        </div>
    )
}


export default ChatUIDe;