// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li::before{
    color:transparent
}

@media screen and (min-width: 768px) {
   .guide{
    width:calc(100vw - 300px);
    position:sticky;
    left:300px;
   }
   .guideNav{
    display: inline-block;
   }
   .guideMenu{
    display:none;
   }

  }
@media screen and (max-width: 767px) {
   .guideNav{
    display: none;
   }
   .guideMenu{
    position:fixed;
    width:100vw;
    top:0;
   }
   /* .pageContent{
    margin-top: 100px;
   } */
  }

  .hidden{
    display: none;
  }

  p{
    font-size: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/component/GuidePage/Deco.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;GACG;IACC,yBAAyB;IACzB,eAAe;IACf,UAAU;GACX;GACA;IACC,qBAAqB;GACtB;GACA;IACC,YAAY;GACb;;EAED;AACF;GACG;IACC,aAAa;GACd;GACA;IACC,cAAc;IACd,WAAW;IACX,KAAK;GACN;GACA;;MAEG;EACJ;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,eAAe;EACjB","sourcesContent":["li::before{\n    color:transparent\n}\n\n@media screen and (min-width: 768px) {\n   .guide{\n    width:calc(100vw - 300px);\n    position:sticky;\n    left:300px;\n   }\n   .guideNav{\n    display: inline-block;\n   }\n   .guideMenu{\n    display:none;\n   }\n\n  }\n@media screen and (max-width: 767px) {\n   .guideNav{\n    display: none;\n   }\n   .guideMenu{\n    position:fixed;\n    width:100vw;\n    top:0;\n   }\n   /* .pageContent{\n    margin-top: 100px;\n   } */\n  }\n\n  .hidden{\n    display: none;\n  }\n\n  p{\n    font-size: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
