import React from 'react'
import VerticalNavDash from '../../component/DashboardPage/VerticalNavDash'
import ConversationClient from '../../component/DashboardPage/ConversationClient'

const ConversationsClientDashPage = () => {
  return (
    <div>
    <VerticalNavDash/>
   <ConversationClient/>
  </div>
  )
}

export default ConversationsClientDashPage