// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 767px) {
    .responsive-side-nav-other{
        margin-left:0;
        width:100vw;
        padding-top:30px;
      }
  }

  @media only screen and (min-width: 768px) {
    .responsive-side-nav-other{
        margin-left:220px;
        width: calc(100vw - 220px);
      }
  } 

.btn-maroon{
    background-color:#9E2063;
     color:white
}  

@media (min-width: 992px) and (max-width: 1199.98px) {
  .custom-col-3-skip {
    flex: 0 0 50%;
    max-width: 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/component/DashboardPage/sidenav.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,WAAW;QACX,gBAAgB;MAClB;EACJ;;EAEA;IACE;QACI,iBAAiB;QACjB,0BAA0B;MAC5B;EACJ;;AAEF;IACI,wBAAwB;KACvB;AACL;;AAEA;EACE;IACE,aAAa;IACb,cAAc;EAChB;AACF","sourcesContent":["@media only screen and (max-width: 767px) {\n    .responsive-side-nav-other{\n        margin-left:0;\n        width:100vw;\n        padding-top:30px;\n      }\n  }\n\n  @media only screen and (min-width: 768px) {\n    .responsive-side-nav-other{\n        margin-left:220px;\n        width: calc(100vw - 220px);\n      }\n  } \n\n.btn-maroon{\n    background-color:#9E2063;\n     color:white\n}  \n\n@media (min-width: 992px) and (max-width: 1199.98px) {\n  .custom-col-3-skip {\n    flex: 0 0 50%;\n    max-width: 50%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
